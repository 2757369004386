<template>
  <div>
    <breadcrumb-base :breadcrumb="breadcrumb" :title="'APIBuyingDiscount'">
    </breadcrumb-base>

    <div class="w-full my-5 mx-3">
      <vx-card :title="$t('APIBuyingDiscount')">

        <div class="vx-row">
          <vs-spacer></vs-spacer>
          <div class="vx-col md:w-1/3 pt-6">
            <vs-input class="inputx pt-8 w-full" type="number"
                      :label="$t('DirectRechargeDiscount')" v-model="drDiscount"/>

<!--            <vs-input class="inputx pt-8 w-full" type="number"-->
<!--                      :label="$t('InternationalTopUpDiscount')" v-model="intlTopUpDiscount"/>-->

            <vs-input class="inputx pt-8 w-full" type="number"
                      :label="$t('InternationalTopUpDiscount')" v-model="intlTopUpDiscount"/>

            <vs-input class="inputx pt-8 w-full" type="number"
                      :label="$t('FlixBusDiscount')" v-model="flixBusDiscount"/>
            <div class="mt-12">
              <vs-button class="flex " style="margin: auto" type="filled" @click="saveDiscounts">{{ $t('Save') }}
              </vs-button>
            </div>
          </div>
          <vs-spacer></vs-spacer>
        </div>
      </vx-card>
    </div>
  </div>

</template>

<script>
export default {
  name: "DynamicProductsDiscounts",
  data() {
    return {

      drDiscount: '',
      intlTopUpDiscount: '',
      flixBusDiscount: '',
      breadcrumb: [
        {title: 'Home', i18n: 'Home', url: '/'},
        {title: 'API Buying Discount', i18n: 'APIBuyingDiscount', active: true},
      ],
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
  },
  methods: {
    saveDiscounts() {
      let dr = 0
      let ir = 0
      let bus = 0
      if (this.drDiscount) dr = this.drDiscount
      if (this.intlTopUpDiscount) ir = this.intlTopUpDiscount
      if (this.flixBusDiscount) bus = this.flixBusDiscount
      const data = {
        'direct_recharge_discount': dr,
        // 'international_top_up_discount': ir,
        'dt_one_discount': ir,
        'flixbus_discount': bus,
        'update': 1,
      }
      this.$vs.loading()
      return this.$store.dispatch('customer/updateSuperAdminDynamicDiscounts', data)
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
        })
        .catch((error) => {
          console.error(error)
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    fetchDiscounts() {

      this.$vs.loading()
      return this.$store.dispatch('customer/updateSuperAdminDynamicDiscounts', {})
        .then((data) => {
          this.$vs.loading.close()
          this.errorFetching = false
          this.drDiscount = data.direct_recharge_discount
          this.intlTopUpDiscount = data.dt_one_discount
          this.flixBusDiscount = data.flixbus_discount
        })
        .catch((error) => {
          console.error(error)
          this.$vs.loading.close()
          this.errorFetching = true
          this.$vs.notify({
            position: 'top-center',
            title: 'Error',
            text: error.response.data.message || error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
  },
  mounted() {
    this.fetchDiscounts();
  }
}
</script>

<style scoped>

</style>
